export function money(number, currency = 'USD', locale = 'en-US') {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
    });
    return formatter.format(number);
}

import moment from 'moment';
export function formatDate(date, returnIfEmpty= '', toFormat = 'DD/MM/YYYY, hh:mm', fromFormat = 'YYYY-MM-DD HH:mm:ss') {
    if (!date) return returnIfEmpty;
    return moment(date, fromFormat).format(toFormat);
}

export function formatWeightKgAndLb(lb, kg) {
    return parseFloat(kg).toFixed(2)+' '+this.$t('kg')+' ('+parseFloat(lb).toFixed(2)+' lb)';
}

export async function showPackagesPopupByRef(order, type, title) {
    await this.$refs[type].show({
        order: order,
        title: title+' #'+order.number,
    });
}

export function doPost (url, hiddenPostParams = {})  {
    let form = document.createElement("form");
    form.action = url;
    form.method = "post";
    form.style.display = "none";

    hiddenPostParams["_token"] = document.querySelector('meta[name="csrf-token"]').content;

    for (let i in hiddenPostParams) {
        if (hiddenPostParams[i] instanceof Array) {
            for (let j in hiddenPostParams[i]) {
                let input = document.createElement("input");
                input.type = "hidden";
                input.name = i + "[]";
                input.value = hiddenPostParams[i][j];

                form.appendChild(input);
            }
        } else {
            let input = document.createElement("input");
            input.type = "hidden";
            input.name = i;
            input.value = hiddenPostParams[i];

            form.appendChild(input);
        }
    }
    document.body.appendChild(form);
    form.submit();
}

export function objectToQueryString(obj) {
    let string = Object.keys(obj)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join('&');
    if (string) {
        string = '?' + string;
    }
    return string;
}

export function formatFileSize(bytes, si= true, dp= 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
}

/**
 *
 * @param number
 * @param n length of decimal
 * @param x length of whole part
 * @param s sections delimiter
 * @param c decimal delimiter
 * @returns {string}
 */
export function formatNumber(number, n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = number.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
}

export function convertWeight(weight, from, to) {
    switch (from) {
        case 'kg':
           switch (to) {
               case 'lb':
                   return weight * 2.20462;
               case 'g':
                   return weight * 1000;
               case 'oz':
                   return weight * 35.274;

           }
           break;
        case 'lb':
            switch (to) {
                case 'kg':
                    return weight * 0.453592;
                case 'g':
                    return weight * 453.592;
                case 'oz':
                    return weight * 16;
            }
            break;
        case 'g':
            switch (to) {
                case 'kg':
                    return weight * 0.001;
                case 'lb':
                    return weight * 0.00220462;
                case 'oz':
                    return weight * 0.035274;
            }
            break;
        case 'oz':
            switch (to) {
                case 'kg':
                    return weight * 0.0283495;
                case 'lb':
                    return weight * 0.0625;
                case 'g':
                    return weight * 28.3495;
            }
            break;
    }
}
