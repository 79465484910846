import { createI18n } from 'vue-i18n';
import messages from "@intlify/unplugin-vue-i18n/messages";

export const allLocales = ["en", "ru"];

export const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: document.querySelector('html').getAttribute('lang'),
    fallbackLocale: "en",
    messages: messages,
});

export async function setLocale (locale) {
    // Load locale if not available yet.
    if (!i18n.global.availableLocales.includes(locale)) {
        const messages = await loadLocale(locale);

        // fetch() error occurred.
        if (messages === undefined) {
            return;
        }

        // Add locale.
        i18n.global.setLocaleMessage(locale, messages);
    }

    // Set locale.
    i18n.global.locale.value = locale;
}

function loadLocale(locale) {
    return fetch(`./lang/${locale}.json`)
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error("Something went wrong!");
        })
        .catch((error) => {
            console.error(error);
        });
}
